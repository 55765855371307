<template>
  <input
    v-model="model"
    class="w-full  p-3 pb-[0.8125rem] mt-2 border rounded resize-none typo-meta-1-bold outline-0 border-grau-500"
    :class="{ 'border-rot-600 text-rot-600 placeholder-rot-600': errorMessage }"
    v-bind="customProps"
    @blur="validation && model && validation(model)"
  />

  <FormsErrorMessage v-if="errorMessage">
    {{ errorMessage }}
  </FormsErrorMessage>
</template>

<script setup lang="ts">
const {
  placeholder = '',
  type = 'text',
  disabled,
  class: classes,
} = defineProps<{
  name: string
  placeholder?: string
  errorMessage?: string
  class?: string | object
  disabled?: boolean
  validation?: (data: string) => void
  type?: string
}>()

const customProps = {
  type,
  disabled,
  class: classes,
  placeholder,
}

const model = defineModel<string>()
</script>
