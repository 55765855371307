<template>
  <div class="flex items-center justify-between">
    <h2 class="typo-teaser-3">Anmelden</h2>

    <ModalCloseButton />
  </div>

  <form class="typo-body-1" @submit.prevent="submit">
    <FormsInputTextInput
      v-model="emailOrUsername"
      name="emailOrUsername"
      :error-message="emailOrUsernameError"
      placeholder="Benutzername / E-Mail-Adresse"
      :validation="validateEmailOrUsername"
    />

    <FormsInputPasswordInput
      v-model="password"
      name="password"
      :error-message="passwordError"
      placeholder="Password"
      :validation="validatePassword"
    />

    <FormsErrorMessage v-if="errorMessage">
      {{ errorMessage }}
    </FormsErrorMessage>

    <NPCommonDefaultButton class="mt-8" :disabled="buttonDisabled">
      Anmelden
    </NPCommonDefaultButton>
  </form>
</template>

<script setup lang="ts">
defineProps<{ errorMessage?: string }>()

const emit = defineEmits<{
  submit: [emailOrUsername: string, password: string]
}>()

const emailOrUsername = ref('')
const emailOrUsernameError = ref('')
function validateEmailOrUsername() {
  emailOrUsernameError.value = useFormValidation().validateEmailOrUsernameInput(
    emailOrUsername.value,
  )
}

const password = ref('')
const passwordError = ref('')
function validatePassword() {
  passwordError.value = useFormValidation().validatePasswordInput(
    password.value,
  )
}

const buttonDisabled = computed(
  () => emailOrUsername.value === '' || password.value === '',
)

function submit() {
  emailOrUsernameError.value = ''
  passwordError.value = ''

  validateEmailOrUsername()
  validatePassword()

  if (emailOrUsernameError.value !== '' || passwordError.value !== '') {
    return
  }

  emit('submit', emailOrUsername.value, password.value)
}
</script>
